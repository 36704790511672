import React from 'react';
import { useSettings } from '@wix/tpa-settings/react';
import { Text, TextButton, TextButtonPriority } from 'wix-ui-tpa';
import ShowMoreText from 'react-show-more-text';
import settingsParams from '../../../settingsParams';
import s from './ProductDescription.scss';
import { useControllerProps } from '../../ControllerContext';
import { Property } from 'csstype/index';

const ProductDescription = () => {
  const settings = useSettings();
  const { product } = useControllerProps().giftCardStore;

  const description = product?.description;

  const moreButton = (
    <>
      <div></div>
      <TextButton
        data-hook="rise-gift-card-product-description-read-more-button"
        priority={TextButtonPriority.link}
      >
        {settings.get(settingsParams.descriptionReadMoreButton)}
      </TextButton>
    </>
  );

  const lessButton = (
    <>
      <div></div>
      <TextButton
        data-hook="rise-gift-card-product-description-less-button"
        priority={TextButtonPriority.link}
      >
        {settings.get(settingsParams.descriptionReadLessButton)}
      </TextButton>
    </>
  );

  return (
    <div
      className={s.description}
      style={{
        textAlign: settings.get(
          settingsParams.descriptionAlignment,
        ) as Property.TextAlign,
      }}
    >
      <p>
        <Text>
          <ShowMoreText
            lines={settings.get(settingsParams.descriptionLimitRows)}
            more={moreButton}
            less={lessButton}
            expanded={false}
            keepNewLines={true}
          >
            {description}
          </ShowMoreText>
        </Text>
      </p>
    </div>
  );
};

export default ProductDescription;
